var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(_vm._s(_vm.$t('add')))],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"id":"condition","label":_vm.$t('condition') + '*',"name":"condition","type":"text","rules":_vm.conditionRules,"required":""},model:{value:(_vm.editedItem.condition),callback:function ($$v) {_vm.$set(_vm.editedItem, "condition", $$v)},expression:"editedItem.condition"}}),_c('v-textarea',{attrs:{"id":"treatment","label":_vm.$t('treatment'),"name":"treatment","type":"text","rows":"2","auto-grow":""},model:{value:(_vm.editedItem.treatment),callback:function ($$v) {_vm.$set(_vm.editedItem, "treatment", $$v)},expression:"editedItem.treatment"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"offset-y":"","transition":"scale-transition","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.editedItem.dateVetCheck),"label":_vm.$t('dateVetCheck'),"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateVetCheck),callback:function ($$v) {_vm.dateVetCheck=$$v},expression:"dateVetCheck"}},[_c('v-date-picker',{attrs:{"locale":this.$i18n.locale || 'bg',"first-day-of-week":1},on:{"input":function($event){_vm.dateVetCheck = false}},model:{value:(_vm.editedItem.dateVetCheck),callback:function ($$v) {_vm.$set(_vm.editedItem, "dateVetCheck", $$v)},expression:"editedItem.dateVetCheck"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"offset-y":"","transition":"scale-transition","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.editedItem.dateLastVetCheck),"label":_vm.$t('dateLastVetCheck'),"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateLastVetCheck),callback:function ($$v) {_vm.dateLastVetCheck=$$v},expression:"dateLastVetCheck"}},[_c('v-date-picker',{attrs:{"locale":this.$i18n.locale || 'bg',"first-day-of-week":1},on:{"input":function($event){_vm.dateLastVetCheck = false}},model:{value:(_vm.editedItem.dateLastVetCheck),callback:function ($$v) {_vm.$set(_vm.editedItem, "dateLastVetCheck", $$v)},expression:"editedItem.dateLastVetCheck"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"offset-y":"","transition":"scale-transition","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.editedItem.dateNextVetCheck),"label":_vm.$t('dateNextVetCheck'),"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateNextVetCheck),callback:function ($$v) {_vm.dateNextVetCheck=$$v},expression:"dateNextVetCheck"}},[_c('v-date-picker',{attrs:{"locale":this.$i18n.locale || 'bg',"first-day-of-week":1},on:{"input":function($event){_vm.dateNextVetCheck = false}},model:{value:(_vm.editedItem.dateNextVetCheck),callback:function ($$v) {_vm.$set(_vm.editedItem, "dateNextVetCheck", $$v)},expression:"editedItem.dateNextVetCheck"}})],1)],1),(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('small',[_vm._v("*"+_vm._s(_vm.$t('requiredFields')))])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","dark":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),_c('DeleteModal',{attrs:{"dialogDelete":_vm.dialogDelete,"title":_vm.$t('confirmDelete')},on:{"cancel":_vm.closeDelete,"ok":_vm.deleteConfirm}}),(_vm.pet)?_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.pet.treatments,"footer-props":_vm.footerProps,"multi-sort":""},scopedSlots:_vm._u([{key:"item.dateVetCheck",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dateVetCheck)))])]}},{key:"item.dateLastVetCheck",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dateLastVetCheck)))])]}},{key:"item.dateNextVetCheck",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dateNextVetCheck)))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,1555325709)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }