<template>
	<v-container fluid>
		<!-- Create/Update Modal -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" small v-bind="attrs" v-on="on"
					><v-icon small> mdi-plus </v-icon>{{ $t('add') }}</v-btn
				>
			</template>
			<v-card>
				<v-card-title>
					<span class="headline">{{ formTitle }}</span>
				</v-card-title>

				<v-card-text>
					<v-form ref="form" lazy-validation>
						<!-- Condition -->
						<v-text-field
							id="condition"
							:label="$t('condition') + '*'"
							name="condition"
							type="text"
							v-model="editedItem.condition"
							:rules="conditionRules"
							required
						></v-text-field>

						<!-- Treatment -->
						<v-textarea
							id="treatment"
							:label="$t('treatment')"
							name="treatment"
							type="text"
							v-model="editedItem.treatment"
							rows="2"
							auto-grow
						></v-textarea>

						<!-- Date Vet Check -->
						<v-menu
							v-model="dateVetCheck"
							:close-on-content-click="false"
							:nudge-right="40"
							offset-y
							transition="scale-transition"
							min-width="150px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									:value="formatDate(editedItem.dateVetCheck)"
									:label="$t('dateVetCheck')"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="editedItem.dateVetCheck"
								:locale="this.$i18n.locale || 'bg'"
								:first-day-of-week="1"
								@input="dateVetCheck = false"
							></v-date-picker>
						</v-menu>

						<!-- Date Last Vet Check -->
						<v-menu
							v-model="dateLastVetCheck"
							:close-on-content-click="false"
							:nudge-right="40"
							offset-y
							transition="scale-transition"
							min-width="150px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									:value="formatDate(editedItem.dateLastVetCheck)"
									:label="$t('dateLastVetCheck')"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="editedItem.dateLastVetCheck"
								:locale="this.$i18n.locale || 'bg'"
								:first-day-of-week="1"
								@input="dateLastVetCheck = false"
							></v-date-picker>
						</v-menu>

						<!-- Date Next Vet Check -->
						<v-menu
							v-model="dateNextVetCheck"
							:close-on-content-click="false"
							:nudge-right="40"
							offset-y
							transition="scale-transition"
							min-width="150px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									:value="formatDate(editedItem.dateNextVetCheck)"
									:label="$t('dateNextVetCheck')"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="editedItem.dateNextVetCheck"
								:locale="this.$i18n.locale || 'bg'"
								:first-day-of-week="1"
								@input="dateNextVetCheck = false"
							></v-date-picker>
						</v-menu>
					</v-form>

					<!-- Loader -->
					<div v-if="isLoading" class="text-center">
						<v-progress-circular
							indeterminate
							color="primary"
						></v-progress-circular>
					</div>
					<small>*{{ $t('requiredFields') }}</small>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="grey" dark @click="close">
						{{ $t('close') }}
					</v-btn>
					<v-btn color="primary" @click="save">
						{{ $t('save') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Delete Modal -->
		<DeleteModal
			:dialogDelete="dialogDelete"
			:title="$t('confirmDelete')"
			@cancel="closeDelete"
			@ok="deleteConfirm"
		/>

		<v-data-table
			v-if="pet"
			:headers="headers"
			:items="pet.treatments"
			:footer-props="footerProps"
			multi-sort
			class="mt-3"
		>
			<template v-slot:item.dateVetCheck="{ item }">
				<span>{{ formatDate(item.dateVetCheck) }}</span>
			</template>

			<template v-slot:item.dateLastVetCheck="{ item }">
				<span>{{ formatDate(item.dateLastVetCheck) }}</span>
			</template>

			<template v-slot:item.dateNextVetCheck="{ item }">
				<span>{{ formatDate(item.dateNextVetCheck) }}</span>
			</template>

			<template v-slot:item.date="{ item }">
				<span>{{ formatDate(item.date) }}</span>
			</template>

			<template v-slot:item.edit="{ item }">
				<v-icon @click="editItem(item)">
					mdi-pencil
				</v-icon>
			</template>
			<template v-slot:item.delete="{ item }">
				<v-icon @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
	name: 'Vaccines',
	components: {
		DeleteModal: () => import('@/components/DeleteModal'),
	},

	data() {
		return {
			pet: null,
			isLoading: false,
			dialog: false,
			dialogDelete: false,
			dateVetCheck: false,
			dateLastVetCheck: false,
			dateNextVetCheck: false,
			editedIndex: -1,
			editedItem: {
				condition: '',
				treatment: '',
				dateVetCheck: '',
				dateLastVetCheck: '',
				dateNextVetCheck: '',
			},
			defaultItem: {
				condition: '',
				treatment: '',
				dateVetCheck: '',
				dateLastVetCheck: '',
				dateNextVetCheck: '',
			},
			conditionRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	computed: {
		headers() {
			return [
				{ text: this.$t('condition'), align: 'start', value: 'condition' },
				{
					text: this.$t('dateVetCheck'),
					align: 'start',
					value: 'dateVetCheck',
				},
				{
					text: this.$t('dateLastVetCheck'),
					align: 'start',
					value: 'dateLastVetCheck',
				},
				{
					text: this.$t('dateNextVetCheck'),
					align: 'start',
					value: 'dateNextVetCheck',
				},
				{ text: this.$t('openEdit'), value: 'edit' },
				{ text: this.$t('delete'), value: 'delete' },
			];
		},
		footerProps() {
			return { 'items-per-page-text': this.$t('rowsPerPage') };
		},
		formTitle() {
			return this.editedIndex === -1 ? this.$t('add') : this.$t('edit');
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		...mapActions('pets', ['getPet', 'editPet']),
		formatDate: date =>
			date ? (date = moment(date).format('DD.MM.YYYY')) : '',
		editItem(item) {
			this.editedIndex = this.pet.treatments.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.pet.treatments.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteConfirm() {
			this.pet.treatments.splice(this.editedIndex, 1);
			this.callEditPet();
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			if (this.editedIndex > -1) {
				Object.assign(this.pet.treatments[this.editedIndex], this.editedItem);
			} else {
				this.pet.treatments.unshift(this.editedItem);
			}
			this.callEditPet();
			this.close();
		},
		async callEditPet() {
			this.isLoading = true;

			try {
				const res = await this.editPet(this.pet);
				this.isLoading = false;
			} catch (error) {
				console.error(error);
				this.isLoading = false;
			}
		},
	},
	async mounted() {
		this.isLoading = true;

		try {
			const res = await this.getPet(this.$route.params.id);

			this.pet = res.data.data;
			this.isLoading = false;
		} catch (error) {
			console.error(error);
			this.isLoading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
